.ag-legacy {
  --ag-foreground-color: #1E1B4D;
  --ag-background-color: #F7F7F7;
  --ag-header-background-color: #F7F7F7;
  --ag-data-color: #1E1B4D;
  --ag-secondary-foreground-color: #1E1B4D;
  --ag-subheader-background-color: #F7F7F7;
  --ag-row-border-color: #1e1b4d59;
  --ag-row-hover-color: #EFF3F6;
  --ag-checkbox-checked-color: #1E1B4D;
  --ag-range-selection-border-color: #1e1b4d59;
  --ag-range-selection-background-color: #1e1b4d59;
  --ag-header-height: 60px;
  --ag-control-panel-background-color: #F7F7F7;
  --ag-selected-row-background-color: #dbdbdb;
  --ag-border-color: #1e1b4d59;
  --ag-selected-tab-underline-color: #1E1B4D;
  --ag-checkbox-unchecked-color: #1E1B4D;
  .ag-column-select-column-label,
  .ag-wrapper,
  .ag-picker-field-wrapper,
  .ag-menu-option-text,
  .ag-input-field-label,
  .ag-label,
  .ag-checkbox-label,
  .ag-menu-option-part,
  .ag-menu-option-icon {
    color: #1E1B4D;
  }
  & input:focus[class^="ag-"][type="text"],
  input:focus[class^="ag-"][type="number"] {
    border-bottom-color: #1E1B4D;
  }
}
